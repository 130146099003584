import { defineStore, acceptHMRUpdate } from 'pinia'
import siteConfig from '@/config.json'
import axios from 'axios'

import { usDateFormatToYYYYMMDD, timestampToShortDateText, timestampsToDateRange } from '@/helpers/datetime'

export const useEventStore = defineStore('EventStore', {
  state: () => {
    return {
      events: [],
      groups: [],
      filters: {} // must be an array of values
    }
  },

  getters: {
    filteredEvents: (state) => {
      state.setFiltersFromUrlParams()
      return state.events.filter((event) => {
        return Object.keys(state.filters).every((key) => {
          if (Array.isArray(state.filters[key])) {
            return state.filters[key].includes(event[key])
          } else {
            return true
          }
        })
      })
    },
  },

  actions: {
    setFiltersFromUrlParams() {
      const params = new URLSearchParams(window.location.search);

      this.filters = Array.from(params.entries()).reduce((filters, [key, value]) => {
        if (!value) {
          return filters
        }
        filters[key] = value.split(',')
        return filters
      }, {})
    },
    setParamsFromFilters() {
      // Start with existing URL parameters
      const searchParams = new URLSearchParams(window.location.search)

      // Remove all existing filter parameters
      Object.keys(this.filters).forEach(key => {
        searchParams.delete(key)
      })

      // Add current filter parameters
      Object.entries(this.filters).forEach(([key, values]) => {
        if (values?.length) {
          searchParams.set(key, values.join(','))
        }
      })

      window.location.search = searchParams.toString()
    },
    setFilter(key, value) {
      if (!value) {
        this.filters[key] = null
      } else {
        this.filters[key] = [value]
      }
      this.setParamsFromFilters()
    },
    formatEvent(event) {
      if (event.image?.itemImageFile) {
        event.imageUrl = `${siteConfig.legacyImagesBaseUrl}/system${event.image.itemImageFile}`
      } else {
        event.imageUrl = `/sites/${siteConfig.key}/${siteConfig.assets.defaultEventImage || siteConfig.assets.defaultClassImage || siteConfig.assets.logo
          }`
      }
      event.name = event.teventName
      event.id = event.teventId
      event.sessions = event.sessions.sort((a, b) => a.date - b.date)
      event.startDateStandard = usDateFormatToYYYYMMDD(event.sessions[0].startDate)
      event.endDateStandard = usDateFormatToYYYYMMDD(event.sessions[event.sessions.length - 1].startDate)
      event.dateRangeStandard = timestampsToDateRange(event.sessions[0].date, event.sessions[event.sessions.length - 1].date)

      // Check if all sessions have same start/end time
      const allSameTime = event.sessions.every(session =>
        session.startTimeFmt === event.sessions[0].startTimeFmt &&
        session.endTimeFmt === event.sessions[0].endTimeFmt
      )
      event.timeLabel = allSameTime ? `${event.startTime} – ${event.endTime}` : 'Various times'

      event.tiers = []
      event.costLow = null
      event.costHigh = null

      event.ticketIdForScheduleId = {}
      Object.keys(event.ticketMap).forEach((ticketId) => {
        event.ticketIdForScheduleId[event.ticketMap[ticketId]] = parseInt(ticketId)
      })
      for (let i = 1; i <= 4; i++) {
        if (event[`publishPrice${i}`]) {
          let cost = event[`cost${i}`]
          if (event.costLow === null) {
            event.costLow = cost
          } else if (cost < event.costLow) {
            event.costLow = cost
          }

          if (event.costHigh === null) {
            event.costHigh = cost
          } else if (cost > event.costHigh) {
            event.costHigh = cost
          }
          event.tiers.push({
            name: event[`prname${i}`],
            cost: cost,
          })
        }
      }
      if (event.tiers.length === 0) {
        event.costSummary = ''
      } else if (event.costLow === event.costHigh) {
        event.costSummary = `$${event.costLow}`
      } else {
        event.costSummary = `$${event.costLow.toFixed(2)} - $${event.costHigh.toFixed(2)}`
      }

      let today = new Date()
      let todaysDateStandard = today.toISOString().split('T')[0]
      event.upcomingSessions = event.sessions?.filter((session) => {
        const sessionDateStandard = new Date(session.date).toISOString().split('T')[0]
        if (todaysDateStandard <= sessionDateStandard) {
          return true
        }
        return false
      })
      event.upcomingSessions = event.upcomingSessions.sort((a, b) => a.date - b.date)
      event.upcomingSessions.forEach((session) => {
        session.dateLabel = timestampToShortDateText(session.date)
        session.timeLabel = `${session.startTimeFmt} – ${session.endTimeFmt}`
      })
      return event
    },

    fetchEvents() {
      this.loading = true

      if (this.events.length) {
        return
      }

      axios
        .get(`${siteConfig.apiBaseUrl}/tickets/allevents`)
        .then((response) => {
          let events = response.data.results?.map((event) => { return this.formatEvent(event) }) || []

          this.events = events.sort((a, b) => {
            if (a.startDateStandard < b.startDateStandard) return -1
            if (a.startDateStandard > b.startDateStandard) return 1
            return 0
          })

          this.groups = [...new Set(this.events.map((evt) => evt.eventGroup).filter((grp) => !!grp))]
        })
    },

    fetchEventById(id) {
      return axios.get(`${siteConfig.apiBaseUrl}/tickets/event/${id}`)
        .then((response) => {
          return this.formatEvent(response.data.results)
        })
    }
  },
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useEventStore, import.meta.hot))
}
